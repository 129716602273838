<template>
  <div class="app-container clearfix">
    <div class="new_table clearfix">
      <!-- 弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="80px" style="width: 550px; margin-left:50px;">
        
        <el-form-item label="角色名称:" prop="name">
          <el-input v-model="temp.name" placeholder="请输入内容" size="small"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="temp.remark" placeholder="请输入备注" size="small"></el-input>
        </el-form-item>


        <el-form-item v-if="dialogStatus == 'update'" label="权限控制:">
          <el-select
          style="width:300px"
            v-model="buttonRole"
            multiple
            collapse-tags
            placeholder="请选择">
            <el-option
              v-for="item in buttons"
              :key="item.id"
              :label="item.buttonName"
              :value="item.id">
            </el-option>
          </el-select>
          <span style="color:red">*选中为取消此按钮权限</span>
        </el-form-item>
        
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
          <el-button size="small" type="primary" @click="dialogStatus==='create'?createData():updateData()">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 弹框 -->
      <div class="left_box">
        <!-- <div style="margin-bottom:20px;" class="btn grid-content bg-purple">
          <div><el-button @click="handleCreate()" type="primary" size="small" plain> <i class="iconfont icon-jiahao"></i> 新增</el-button></div>
          <div><el-button @click="handleUpdate()" type="primary" size="small" plain> <i class="iconfont icon-bianji"></i> 编辑</el-button></div>
          <div><el-button @click="del()" type="danger" size="small" plain><i class="iconfont icon-shanchu"></i> 删除</el-button></div>
        </div> -->
        <div class="left_tree">
          <h5 class="jsgl">权限管理</h5>
            <div v-for="(item,index) in list" :key="index" class="item_p">
              <i :class="{curs:roleId == item.id}" class="el-icon-caret-right"></i>
              <p @click="clickLeft(item.id)" :class="{curs:roleId == item.id}">{{item.name}}</p>
            </div>
        </div>
      </div>
      <div class="right_box">
        <el-button @click="createRole()" type="primary" size="small" plain><i class="iconfont icon-baocun"></i> 保存</el-button>
        <el-tree
          ref="tree"
          :data="data1"
          show-checkbox
          default-expand-all
          node-key="id"
          highlight-current
          :props="defaultProps"
          v-loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getSysRoles,saveSysRole,DeleteSysRole,getFunctionList,roleBingFunction,sysButtonSave,sysButtonRoleList } from '@/api/sys'
export default {
  name: 'user-role',
  data() {
    return {
      list: [],
      roleId: 0,
      select:[],
      buttons: [],
      buttonRole: [],
      dialogFormVisible: false,
      loading: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      arrs:[],
      temp: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '角色名不能为空', trigger: 'change' }],
      },
      data1:[],
      defaultProps: {
        children: 'children',
        label: 'name',
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getSysRoles({}).then(res => {
        this.list = res.data.data.list
        this.roleId = res.data.data.list[0].id
        this.loading = true
        this.getList2();
      })
    },
    getList2() {
      getFunctionList({id:this.roleId}).then(res => {
        this.data1 = res.data.data.list
        this.select = res.data.data.select
        this.setCheckedKeys()
      })
      this.loading = false
    },
    //树状回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys(this.select)
    },
    // 右侧树状保存
    createRole() {
      this.arrs = this.$refs.tree.getCheckedKeys()
      roleBingFunction({roleId:this.roleId,funIds:this.arrs}).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
          message: '保存成功',
          type: 'success'
          })
          this.getList2()
        }
      })
    },
    resetTemp() {
      this.temp = {
        name: '',
        remark:''
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      // this.$nextTick(() => {
      //   this.$refs['dataForm'].clearValidate()
      // })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          saveSysRole(this.temp).then((res) => {
            this.dialogFormVisible = false
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.$bus.emit('addUpdate')
              this.getList()
            }
          })
        }
      })
    },
    clickLeft(id) {
      this.roleId = id
      this.getList2()
    },
    handleUpdate() {
      let items = {}
      // 遍历根据id找item
      this.list.map(v =>{
        if(this.roleId == v.id){
          items = v
        }
      })
      //查询权限按钮
      sysButtonRoleList({roleId: this.roleId}).then(res=>{
        this.buttons = res.data.data.buttonList
        this.buttonRole = res.data.data.list
      })

      // return
      this.temp = items
      // this.temp = Object.assign({}) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          saveSysRole(tempData).then((res) => {
            this.getList()
            this.dialogFormVisible = false
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.$bus.emit('addUpdate')
              this.getList()
            }
          })

          let param = {
            roleId: this.roleId,
            items: this.buttonRole 
          }
          sysButtonSave(param)
        }
      })
    },
    del() {
      this.$confirm('确认删除该角色', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteSysRole({id:this.roleId}).then(res => {
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('addUpdate')
            this.getList()
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.left_box .el-button{
  width: 60px;
  // padding: 7px 0 !important;
}
.bottom_big{
  height:660px;
  overflow: hidden;
  padding-left: 12px;
}
.amod_ad {
  animation: ad 0.3s 0s both;
}
.amod_more {
  animation: more 0.3s 0s both;
}
.amod {
  // margin-top: 60%;
  height: 0;
}
@-webkit-keyframes ad {
  0% {
    height: 660px;
  }

  100% {
    height: 0px;
  }
}
@-webkit-keyframes more {
  0% {
    height: 0px;
  }
  100% {
    height: 660px;
    
  }
}
.app-container{
  position: relative;
  overflow-y: auto;
}
.title_box{
  width: 100%;
  line-height: 36px;
  height: 36px;
  font-size: 15px;
  color: #333;
  .el-col{
    padding-left: 5px;
  }
}
.new_table{
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: calc(100vh - 214px);
}
.right_box{
  float: left;
  width: 30%;
  min-height: calc(100vh - 214px);
  padding-left:22px;
  border-left: 5px solid #E6EEF8;
  overflow-y: auto;
  margin-top:10px;
  .el-tree {
    padding-left:20px;
    padding-top: 20px;
  }
}
.left_box{
  float: left;
  width: 330px;
  min-height: calc(100vh - 146px);
  padding: 10px;
  overflow-y: auto;
  text-align: center;
  .btn {
    display: flex;
    justify-content: space-evenly;
  }
}
.borders_6{
  border-left: 1px solid #eee;
}
.left_tree{
  text-align: left;
  padding-left: 30px;
  h5{
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 15px;
    color: #39b0d2;
    line-height: 30px;
    cursor: pointer;
    i{
      color: $color-main !important;
      line-height: 30px !important;
      transition: transform .3s ease-in-out;
    }
  }
  i{
    display: inline-block;
    font-size: 14px;
    color: #c0c4cc;
    line-height: 22px;
    margin-right: 2px;
  }
  
  p{
    display: inline-block;
    cursor:pointer;
    transition: all .4s;
    font-size: 14px;
    line-height: 30px;
    
  }
}
.el-checkbox{
  width: 160px !important;
  margin-right: 20px;
  margin-bottom: 8px;
}
.el-checkbox:nth-child(4n-1){
  margin-right: 0;
}
.el-checkbox+.el-checkbox{
  margin-left: 0;
}
.el-col{
  padding-left: 5px;
}
.borders{
  height: 30px;
  line-height: 28px;
  .el-row{
    border-right: 1px solid #eee;
  }
  .el-row:last-child{
    border-bottom: none;
  }
  .el-row:nth-child(1){
    border-top:0px 
  }
}
.el-row:last-child{
  border-bottom: none
}
.el-row{
    border-bottom: 1px solid #eee
  }
.el-col-18 .borders_4{
    border-left: 1px solid #eee;
  }
.bigborder{
  border: 1px solid #ccc;
  min-height: calc(100vh - 214px);
  // margin: 15px 0 20px 0;
  margin: 15px 0 0 0;
}
.borders_3{
  border-left: 1px solid #eee;
}
.curs{
  // background: #F15A24;
  color: $color-main !important;
}
.borders_4   {
  border-left: 1px solid #eee;

}
</style>